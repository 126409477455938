import classNames from "classnames"
import Image from "next/image"

import { Title6 } from "@bounce/design-system"
import { Button } from "@bounce/web-components"

import { useAppStickyBanner } from "../../../contexts/AppStickyBannerContext"

import CloseIcon from "@bounce/assets/icons/Cross.svg"

const AppStickyBanner = () => {
  const { config, visible, href, onClose, onPressUseApp } = useAppStickyBanner()

  const shouldWrapText = config?.cta.length && config.cta.length > 10

  if (!visible) {
    return null
  }

  return (
    <div className="flex max-w-full flex-row justify-between gap-2 border-b-[1px] border-neutral-300 bg-white p-4">
      <div className={classNames("flex grow flex-row justify-between gap-2", shouldWrapText && "flex-wrap")}>
        <div className="flex flex-row items-center gap-2">
          {!!config?.image && <Image className="h-full w-16 shrink grow-0" src={config.image} alt="image" />}
          <div>
            <Title6 className="text-sm">{config?.title}</Title6>
            {config?.description}
          </div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Button href={href} className="min-h-0 px-3 py-2 text-sm" onClick={onPressUseApp}>
            {config?.cta ?? ""}
          </Button>
        </div>
      </div>
      <button onClick={onClose}>
        <CloseIcon className="h-5 w-5" />
      </button>
    </div>
  )
}

export default AppStickyBanner
