import { DEFAULT_LANGUAGE, useTranslation } from "@bounce/i18n"

import { useAppStickyBanner } from "../../../contexts/AppStickyBannerContext"
import type { NavbarProps } from "@/components/Generic/Navbar/Navbar"
import { Product } from "@/types"

import Logo from "@bounce/assets/images/logo/logo-with-text.svg"

export type UseNavbarOptions = Pick<NavbarProps, "alternates" | "languages">

export const useNavbar = ({ alternates, languages }: UseNavbarOptions): NavbarProps => {
  const { t } = useTranslation()
  const { setBannerHidden } = useAppStickyBanner()

  const navbarProps: NavbarProps = {
    product: Product.LuggageStorage,
    Logo,
    links: {
      homepage: { href: "/" },
      account: {
        children: t("cmp.luggageStorage.navbar.bookings", "My bookings"),
        href: "/s/reservations",
      },
      auth: { children: t("cmp.luggageStorage.navbar.login", "Log in"), href: "/s/login" },
      desktop: [
        {
          children: t("cmp.luggageStorage.navbar.reviews", "Reviews"),
          href: "/reviews",
        },
        {
          children: t("cmp.luggageStorage.navbar.careers", "Careers"),
          href: "/careers",
          locale: DEFAULT_LANGUAGE,
        },
        {
          children: t("cmp.luggageStorage.navbar.partner", "Become a Partner"),
          href: "/partner",
        },
      ],
      mobile: [
        {
          children: t("cmp.luggageStorage.navbar.topCities", "Top cities"),
          href: "/cities",
        },
        {
          children: t("cmp.luggageStorage.navbar.reviews", "Reviews"),
          href: "/reviews",
        },
        {
          children: t("cmp.luggageStorage.navbar.blog", "Blog"),
          href: "/blog",
          locale: DEFAULT_LANGUAGE,
        },
        {
          children: t("cmp.luggageStorage.navbar.careers", "Careers"),
          href: "/careers",
          locale: DEFAULT_LANGUAGE,
        },
      ],
    },
    languages,
    alternates,
    onToggleMenu: menuOpen => setBannerHidden(menuOpen),
  }

  return navbarProps
}
