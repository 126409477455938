import type { StaticImageData } from "next/image"
import { createContext, useContext, useEffect, useState } from "react"

import { Analytics } from "@bounce/analytics"
import { Paragraph2 } from "@bounce/design-system"
import { useTranslation } from "@bounce/i18n"
import type { Nullable } from "@bounce/util"
import { useIsMobile } from "@bounce/web-components"

import { FeatureFlag, useMultiVariantFlag } from "../config/flags"
import { useAppStoreUrl } from "../hooks/useAppStoreUrl"
import { InteractionEvents } from "../libs/analytics/events"

import StarFilledIcon from "@bounce/assets/icons/Star-filled.svg"
import PhoneUiImage from "@bounce/assets/illustrations/phone-ui-01@2x.webp"

type AppStickyBannerProviderProps = {
  children: React.ReactNode
}

type BannerFlagVariantValue = "easier" | "faster" | "rating"

type BannerFlagValue = BannerFlagVariantValue | "base"

type BannerFlagConfig = {
  image?: StaticImageData
  title: string
  description: React.ReactNode | string
  cta: string
}

type AppStickyBannerContextProps = {
  config: Nullable<BannerFlagConfig>
  visible: boolean
  href: string
  onClose: () => void
  onPressUseApp: (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  setBannerHidden: (hidden: boolean) => void
}

const AppStickyBannerContext = createContext<AppStickyBannerContextProps>({
  config: null,
  visible: false,
  href: "",
  onClose: () => {},
  onPressUseApp: () => {},
  setBannerHidden: () => {},
})
AppStickyBannerContext.displayName = "AppStickyBannerContext"

export const AppStickyBannerProvider = ({ children }: AppStickyBannerProviderProps) => {
  const { t } = useTranslation()
  const [bannerVariant] = useMultiVariantFlag<BannerFlagValue>(FeatureFlag.StickyAppBanner, "base")
  const [dismissed, setDismissed] = useState(false)
  const [hidden, setHidden] = useState(false)
  const { href } = useAppStoreUrl({
    app: "luggageStorage",
    eventProps: { location: "AppStickyBanner", cta: bannerVariant },
    campaignName: "marketing-AppStickyBanner-" + bannerVariant,
  })
  const isMobile = useIsMobile()

  const configs: Record<BannerFlagValue, BannerFlagConfig> = {
    base: {
      title: "",
      description: "",
      cta: "",
    },
    easier: {
      image: PhoneUiImage,
      title: t("cmp.appStickyBanner.easier.title", "It's easier on the app!"),
      description: (
        <Paragraph2 className="text-neutral-700">
          {t("cmp.appStickyBanner.easier.description", "Access your booking on the go.")}
        </Paragraph2>
      ),
      cta: t("cmp.appStickyBanner.cta", "Use app"),
    },
    faster: {
      image: PhoneUiImage,
      title: t("cmp.appStickyBanner.faster.title", "It's faster on the app!"),
      description: (
        <Paragraph2 className="text-neutral-700">
          {t("cmp.appStickyBanner.faster.description", "Quicker drop off and pick up.")}
        </Paragraph2>
      ),
      cta: t("cmp.appStickyBanner.cta", "Use app"),
    },
    rating: {
      title: t("cmp.appStickyBanner.rating.title", "Bounce Luggage Storage"),
      description: (
        <div className="flex flex-row items-center">
          <Paragraph2 className="mr-1 text-neutral-800">
            {/* Hardcoding the app rating since it's an experiment */}
            {t("cmp.appStickyBanner.rating.descriptionV2", "4.9/5 in App Store")}
          </Paragraph2>
          {[...Array(5).keys()].map(i => (
            <StarFilledIcon key={"star_" + i} className="h-4 w-4 text-yellow-700" />
          ))}
        </div>
      ),
      cta: t("cmp.appStickyBanner.cta", "Use app"),
    },
  }

  /* Do not display banner if:
     - user is not on mobile web
     - user has dismissed it
     - user got assigned the base variant
     - we want it hidden (e.g. when the menu is open)
  */
  const visible = isMobile && !hidden && !dismissed && bannerVariant !== "base"

  const onClose = () => {
    Analytics.track(InteractionEvents.LuggageStorageAppStickyBannerDismissed, { variant: bannerVariant })
    setDismissed(true)
  }

  const onPressUseApp = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()

    Analytics.track(InteractionEvents.LuggageStorageAppStickyBannerCTAPressed, { variant: bannerVariant })

    location.assign(ev.currentTarget.href)
  }

  useEffect(() => {
    if (!visible) return

    Analytics.track(InteractionEvents.LuggageStorageAppStickyBannerDisplayed, { variant: bannerVariant })
  }, [visible, bannerVariant])

  return (
    <AppStickyBannerContext.Provider
      value={{
        href,
        visible,
        config: configs[bannerVariant],
        onClose,
        onPressUseApp,
        setBannerHidden: setHidden,
      }}>
      {children}
    </AppStickyBannerContext.Provider>
  )
}

export const useAppStickyBanner = () => useContext(AppStickyBannerContext)
